<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field reservationDate">
            <div class="title">예약일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약시간</div>
            <ul class="content">
              <li class="item time" style="width: 120px">
                <ejs-dropdownlist
                  ref="hopeTimeZone"
                  v-model="searchConditions.hopeTimeZone"
                  :dataSource="searchOptions.hopeTimeListOption"
                  :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">SMS전송여부</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="smsSendFlag"
                  v-model="searchConditions.smsSendFlag"
                  :dataSource="searchOptions.smsSendFlagOptions"
                  :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">단체종류</div>
            <ul class="content">
              <li class="item" style="width: 160px">
                <ejs-multiselect
                  ref="grpKindMultiSelect"
                  v-model="searchConditions.grpKindList"
                  placeHolder="전체"
                  :dataSource="searchOptions.grpKindOptions"
                  :fields="commonCodeFields"
                  :value="searchConditions.grpKindList"
                  cssClass="lookupDetail-condition-multiselect"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약자명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.resveName"
                  @change="onResveNameInputChange"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button button-div="GET" @click.native="openMemberPopup">
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchConditions.aditTeamFlag"
                      />
                      <i></i>
                      <div class="label">추가팀 보기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onReservationBatchSmsSendViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">예약 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      :ignore="isPopupOpened"
                      :shortcut="{key: 'F7'}"
                      shortcut-key="ReservationBatchSmsSend.shortcuts.sendSms"
                      @click.native="onSmsSendButtonClicked"
                  >
                    SMS전송
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="reservationBatchSmsSendListGrid"
                :allowResizing="true"
                :dataSource="reservationBatchSmsSendList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="reservationBatchSmsSendListGridColumn"
                :provides="grid"
                :aggregates="aggregates"
                :allowPaging="true"
                :allowEditing="true"
                :pageSettings="reservationBatchSmsSendPageSettings"
                @queryCellInfo="queryCellInfo"
                @gridCheckboxChanged="gridCheckboxChanged"
                @actionComplete="reservationBatchSmsSendListGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">코스</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="courseCodeMultiSelect"
                        v-model="searchConditions.resveCourseList"
                        placeHolder="전체"
                        :dataSource="searchOptions.courseCodeOptions"
                        :fields="commonCodeFields"
                        :value="searchConditions.resveCourseList"
                        cssClass="lookupDetail-condition-multiselect"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">영업구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="bsnCodeMultiSelect"
                        v-model="searchConditions.resveBsnCodeList"
                        placeHolder="전체"
                        :dataSource="searchOptions.bsnCodeOptions"
                        :fields="commonCodeFields"
                        :value="searchConditions.resveBsnCodeList"
                        cssClass="lookupDetail-condition-multiselect"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">부구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="partDivMultiSelect"
                        v-model="searchConditions.resvePartDivList"
                        placeHolder="전체"
                        :dataSource="searchOptions.partDivOptions"
                        :fields="commonCodeFields"
                        :value="searchConditions.resvePartDivList"
                        cssClass="lookupDetail-condition-multiselect"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">요일</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="dwCodeMultiSelect"
                        v-model="searchConditions.resveDwcodeList"
                        placeHolder="전체"
                        :dataSource="searchOptions.dwCodeOptions"
                        :fields="commonCodeFields"
                        :value="searchConditions.resveDwcodeList"
                        cssClass="lookupDetail-condition-multiselect"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회원구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="memberDivMultiSelect"
                        v-model="searchConditions.resveMemberDivList"
                        placeHolder="전체"
                        :dataSource="searchOptions.memberDivOptions"
                        :fields="commonCodeFields"
                        :value="searchConditions.resveMemberDivList"
                        cssClass="lookupDetail-condition-multiselect"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">단체명</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.resveGrpName"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">연락처</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.resvePhoneEndNum"
                        placeHolder="연락처 뒤 4자리"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">비고</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.resveRemarks"
                        @keydown.native="preventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onReservationBatchSmsSendDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <batch-sms-send-popup
      v-if="isBatchSmsSendPopupOpen"
      ref="batchSmsSendPopup"
      @popupClosed="batchSmsSendPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Edit,
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  VirtualScroll,
  Page,
} from "@syncfusion/ej2-vue-grids";
import batchSmsSendPopup from "../common/BatchSmsSendPopup";

import { getReservationAllSMSSend } from "@/api/reservation";
import {
  getDayColorValue,
  getFormattedDate,
} from '@/utils/date';
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodeGetComCodeDefaultValue, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import {gridUtilGetMemberNoColumnAccess, gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import { formPreventEnterEvent } from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "ReservationBatchSmsSend",
  components: {
    InputText,
    InputDateRange,
    memberSelectPopup,
    ejsGridWrapper,
    batchSmsSendPopup,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());
    this.searchOptions.grpKindOptions = commonCodesGetCommonCode(
      "GRP_KIND",
      true
    );
    this.searchOptions.courseCodeOptions = commonCodesGetCommonCode(
      "COURSE_CODE",
      true
    );
    this.searchOptions.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE",
      true
    );
    this.searchOptions.partDivOptions = commonCodesGetCommonCode(
      "PART_DIV",
      true
    );
    this.searchOptions.dwCodeOptions = commonCodesGetCommonCode(
      "DW_CODE",
      true
    );
    this.searchOptions.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV",
      true
    );
    this.searchOptions.hopeTimeListOption = commonCodesGetCommonCode("HOPE_TIME_ZONE", true);
    this.searchOptions.smsSendFlagOptions = [
      {
        comCode: "%",
        comName: "전체",
      },
      {
        comCode: "1",
        comName: "발송",
      },
      {
        comCode: "2",
        comName: "미발송",
      },
    ];
  },
  computed: {
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isBatchSmsSendPopupOpen
      );
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "ReservationBatchSmsSend.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
    aggregates() {
      return [
        {
          columns: [
            {
              field: 'select',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'teamCnt',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: () => {
                const gridData = this.$refs.reservationBatchSmsSendListGrid?.getGridBatchData();
                const teamCnt = gridData
                  ?.map(item => item.teamCnt)
                  ?.reduce((acc, cur) => cur + acc, 0);
                return teamCnt > 0 ? `${teamCnt} 건` : '-';
              },
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      count: 0,
      reservationBatchSmsSendListLength: 0,
      grid: [
        Edit,
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      reservationBatchSmsSendPageSettings: { pageSize: 50 },
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        hopeTimeZone: "ALL",
        smsSendFlag: "%",
        grpKindList: [],
        resveName: null,
        membershipId: null,
        resveCourseList: [],
        resveBsnCodeList: [],
        resvePartDivList: [],
        resveDwcodeList: [],
        resveMemberDivList: [],
        resveGrpName: null,
        resveGrpNo: null,
        resvePhoneEndNum: null,
        resveRemarks: null,
        aditTeamFlag: false,
      },
      searchOptions: {
        hopeTimeListOption: null,
        smsSendFlagOptions: null,
        grpKindOptions: null,
        courseCodeOptions: null,
        bsnCodeOptions: null,
        partDivOptions: null,
        dwCodeOptions: null,
        memberDivOptions: null,
      },
      toExcludeIndexList: [],
      reservationBatchSmsSendList: [],
      reservationBatchSmsSendListGridColumn: [
        {
          field: "resveNo",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "select",
          headerText: "제외",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          minWidth: 16,
          width: 60,
          textAlign: "center",
        },
        {
          field: "resveDate",
          headerText: "예약일자",
          allowEditing: false,
          textAlign: "center",
          width: 90,
          type: "string",
        },
        {
          field: "dwCode",
          headerText: "요일",
          allowEditing: false,
          textAlign: "center",
          groupCode: "DW_CODE",
          isCommonCodeField: true,
          width: 60,
          type: "string",
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          allowEditing: false,
          textAlign: "center",
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
          width: 90,
          type: "string",
        },
        {
          field: "resveCourseName",
          headerText: "코스",
          allowEditing: false,
          textAlign: "center",
          width: 90,
          type: "string",
        },
        {
          field: "resveTime",
          headerText: "시간",
          allowEditing: false,
          textAlign: "center",
          width: 60,
          type: "string",
        },
        {
          field: "partDiv",
          headerText: "부구분",
          allowEditing: false,
          textAlign: "center",
          groupCode: "PART_DIV",
          isCommonCodeField: true,
          width: 70,
          type: "string",
        },
        {
          field: "holeDiv",
          headerText: "홀구분",
          allowEditing: false,
          textAlign: "center",
          groupCode: "HOLE_DIV",
          isCommonCodeField: true,
          width: 70,
          type: "string",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          allowEditing: false,
          width: 110,
          type: "string",
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          allowEditing: false,
          textAlign: "center",
          width: 90,
          type: "string",
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "memberDiv",
          headerText: "회원구분",
          allowEditing: false,
          groupCode: "MEMBER_DIV",
          isCommonCodeField: true,
          width: 110,
          type: "string",
        },
        {
          field: "grpKind",
          headerText: "단체종류",
          allowEditing: false,
          groupCode: "GRP_KIND",
          isCommonCodeField: true,
          width: 100,
          type: "string",
        },
        {
          field: "grpName",
          headerText: "단체명",
          allowEditing: false,
          width: 100,
          type: "string",
        },
        {
          field: "teamCnt",
          headerText: "팀수",
          allowEditing: false,
          textAlign: "center",
          width: 60,
          type: "number",
          format: "N",
        },
        {
          field: "resveCourseTime",
          headerText: "코스/시간",
          allowEditing: false,
          width: 190,
          type: "string",
        },
        {
          field: "memberContactTel",
          headerText: "예약자연락처",
          allowEditing: false,
          textAlign: "center",
          valueAccessor: this.gridUtilGetTelColumnAccess,
          width: 120,
          type: "string",
        },
        {
          field: "cmpnName",
          headerText: "동반자",
          allowEditing: false,
          width: 240,
          type: "string",
        },
        {
          field: "transName",
          headerText: "위임자명",
          allowEditing: false,
          width: 100,
          type: "string",
        },
        {
          field: "transContactTel",
          headerText: "위임자연락처",
          allowEditing: false,
          textAlign: "center",
          valueAccessor: this.gridUtilGetTelColumnAccess,
          width: 130,
          type: "string",
        },
        {
          field: "resveMngrName",
          headerText: "예약담당자명",
          allowEditing: false,
          width: 120,
          type: "string",
        },
        {
          field: "resveMngrHp",
          headerText: "예약담당자연락처",
          allowEditing: false,
          textAlign: "center",
          valueAccessor: this.gridUtilGetTelColumnAccess,
          width: 150,
          type: "string",
        },
        {
          field: "smsSendFlag",
          headerText: "SMS여부",
          allowEditing: false,
          textAlign: "center",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          width: 110,
        },
        {
          field: "smsSendCnt",
          headerText: "SMS전송횟수",
          allowEditing: false,
          textAlign: "center",
          width: 130,
          type: "number",
          format: "N",
        },
        {
          field: "smsSendDt",
          headerText: "SMS전송일시",
          allowEditing: false,
          textAlign: "center",
          width: 150,
          type: "string",
        },
        {
          field: "promtnName",
          headerText: "프로모션",
          allowEditing: false,
          width: 100,
          type: "string",
        },
        {
          field: "remarks",
          headerText: "비고",
          allowEditing: false,
          width: 240,
          type: "string",
        },
        {
          field: "resveChannel",
          headerText: "예약채널",
          allowEditing: false,
          groupCode: "RESVE_CHANNEL",
          isCommonCodeField: true,
          width: 100,
          type: "string",
        },
        {
          field: "smsRecptnDiv",
          headerText: "SMS 수신동의",
          allowEditing: false,
          textAlign: "center",
          groupCode: "SMS_RECPTN_DIV",
          isCommonCodeField: true,
          width: 120,
          type: "string",
        },
        {
          field: "insertName",
          headerText: "등록자",
          allowEditing: false,
          width: 100,
          type: "string",
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          allowEditing: false,
          textAlign: "center",
          width: 150,
          type: "string",
        },
        {
          field: "updateName",
          headerText: "수정자",
          allowEditing: false,
          width: 100,
          type: "string",
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          allowEditing: false,
          textAlign: "center",
          width: 150,
          type: "string",
        },
      ],
      isSearchDetailPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isBatchSmsSendPopupOpen: false,
    };
  },
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    gridUtilGetTelColumnAccess: gridUtilGetTelColumnAccess,
    queryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      const value = data[field];
      if (field === "dwCode") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", value);
      } else if (field === "cmpnName" && data.cmpnInfos) {
        cell.innerHTML = data.cmpnInfos
          ?.map(item => `<span style="color: ${commonCodesGetColorValue("SEX_CODE", item.sexCode)}">${item.cmpnName}</span>`)
          ?.join(', ');
      }
      if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if ([
        "teamCnt",
        "smsSendCnt",
      ].includes(field) && data[field] === 0) {
        cell.innerText = "-";
      }
    },
    gridCheckboxChanged(args) {
      const {
        columnName: field,
        rowData
      } = args;

      if (field === "select") {
        const hasRIdx = this.toExcludeIndexList
          ?.findIndex(item => item === rowData._rid);
        const findIndex = this.reservationBatchSmsSendList
          ?.findIndex(item => item._rid === rowData._rid);

        if (findIndex > -1) {
          if (hasRIdx > -1) {
            this.reservationBatchSmsSendList[findIndex].select = false;
            delete this.toExcludeIndexList[hasRIdx];
            this.toExcludeIndexList = this.toExcludeIndexList
                ?.filter(item => !!item);
          } else {
            this.reservationBatchSmsSendList[findIndex].select = true;
            this.toExcludeIndexList.push(rowData._rid);
          }
          this.$forceUpdate();
        }
      }
    },
    dwCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("DW_CODE", code);
      }
      return code;
    },
    bsnCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("BSN_CODE", code);
      }
      return code;
    },
    partDivValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("PART_DIV", code);
      }
      return code;
    },
    holeDivValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("HOLE_DIV", code);
      }
      return code;
    },
    openMemberPopup() {
      this.isMemberSelectPopupOpen = true;

      let memberData = {
        memberNameNo: this.searchConditions.resveName,
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      this.searchConditions.resveName = data.selectedRowData.memberName;
      this.searchConditions.membershipId = data.selectedRowData.membershipId;
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onInitButtonClicked() {
      this.searchConditions.resveCourseList = [];
      this.searchOptions.courseCodeOptions.forEach((data) => {
        this.searchConditions.resveCourseList.push(data.comCode);
      });

      this.searchConditions.resveBsnCodeList = [];
      this.searchOptions.bsnCodeOptions.forEach((data) => {
        this.searchConditions.resveBsnCodeList.push(data.comCode);
      });

      this.searchConditions.resvePartDivList = [];
      this.searchOptions.partDivOptions.forEach((data) => {
        this.searchConditions.resvePartDivList.push(data.comCode);
      });

      this.searchConditions.resveDwcodeList = [];
      this.searchOptions.dwCodeOptions.forEach((data) => {
        this.searchConditions.resveDwcodeList.push(data.comCode);
      });

      this.searchConditions.resveMemberDivList = [];
      this.searchOptions.memberDivOptions.forEach((data) => {
        this.searchConditions.resveMemberDivList.push(data.comCode);
      });

      this.searchConditions.resveGrpName = null;
      this.searchConditions.resveGrpNo = null;
      this.searchConditions.resvePhoneEndNum = null;
      this.searchConditions.resveRemarks = null;
    },
    onReservationBatchSmsSendDetailViewClicked() {
      this.onReservationBatchSmsSendViewClicked();
    },
    onSmsSendButtonClicked() {
      // const batchCurrentViewRecords = this.$refs.reservationBatchSmsSendListGrid.getBatchCurrentViewRecords();
      const batchCurrentViewRecords =
        JSON.parse(
          JSON.stringify(
            this.$refs.reservationBatchSmsSendListGrid.getFilteredRecords().length > 0
              ? this.$refs.reservationBatchSmsSendListGrid.getFilteredRecords()
              : this.reservationBatchSmsSendList
          )
        )
          ?.filter(item => this.toExcludeIndexList.every(i => i !== item._rid))
          ?.map((item, index) => {
            item._rid = index + 1;
            return item;
          });

      console.log('batchCurrentViewRecords.===>', batchCurrentViewRecords);

      if (!(batchCurrentViewRecords.length > 0)) {
        this.errorToast("SMS 전송할 자료가 없습니다.");
        return;
      }

      // if (batchCurrentViewRecords.length > 500) {
      //   this.errorToast("500건 이하만 전송 가능합니다");
      //   return;
      // }

      let smsSendInfo = {
        smsSendType: "G_RESVE",
        smsDiv: "RESERVATION_BATCH",
        smsSendList: [],
      };

      for (const idx in batchCurrentViewRecords) {
        const data = batchCurrentViewRecords[idx];
        const sendDataList = [];
        let sendData = {};
        sendData.sendKey = data.resveNo;
        sendData.timeId = data.timeId;
        sendData.resveId = data.resveId;
        sendData.resveDate = data.resveDate;
        sendData.dwName = data.dwName;
        sendData.grpName = data.grpName;
        sendData.grpNo = data.grpNo;
        sendData.resveCourse = data.resveCourseName;
        sendData.resveTime = data.resveTimes;
        sendData.resveCourseTime = data.resveCourseTime;
        sendData.transFlag = false;
        sendData.bsnCode = data.bsnCode;
        sendData.cmpnInfos = data.cmpnInfos;
        sendData.resveNo = data.resveNo;
        sendData.teamCnt = `${data.teamCnt}팀`;
        sendData.caddieSelfDiv = data.selfFlag ? "셀프" : "동반";
        sendData.day = data.day;

        if (data.smsRecptnDiv === "B") {
          if (data.resveMngrHp) {
            sendData.receiverName = data.resveName;
            sendData.name =
              data.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                data.memberDiv,
                1
              );
            sendData.contactTel = data.resveMngrHp;

            sendDataList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (data.smsRecptnDiv === "A") {
          sendData.receiverName = data.resveName;
          sendData.name =
            data.resveName +
            " " +
            commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
              "MEMBER_DIV",
              data.memberDiv,
              1
            );

          if (data.memberContactTel) {
            sendData.contactTel = data.memberContactTel;

            sendDataList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (data.resveMngrHp) {
            sendData.contactTel = data.resveMngrHp;

            sendDataList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else {
          if (data.memberContactTel) {
            sendData.receiverName = data.resveName;
            sendData.name =
              data.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                data.memberDiv,
                1
              );
            sendData.contactTel = data.memberContactTel;

            sendDataList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }

        if (data.transName) {
          if (data.transContactTel) {
            sendData.receiverName = data.transName;
            sendData.name =
              data.resveName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                data.memberDiv,
                1
              );
            sendData.contactTel = data.transContactTel;
            sendData.transFlag = true;

            sendDataList.push(JSON.parse(JSON.stringify(sendData)));
          }

          sendDataList.forEach(item => {
            item.transInfo = `◎ 위임자 : ${data.transName}`;
            item.name = item.name +
              "(" +
              data.transName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                "MEMBER_DIV",
                data.memberDiv,
                1
              ) +
              ")";
          });
        }

        smsSendInfo.smsSendList = smsSendInfo.smsSendList.concat(sendDataList);
      }

      this.isBatchSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.batchSmsSendPopup.showBatchSmsSendPopup(smsSendInfo);
      });
    },
    batchSmsSendPopupClosed() {
      this.isBatchSmsSendPopupOpen = false;
    },
    onReservationBatchSmsSendViewClicked() {
      this.getReservationAllSMSSend();
    },
    async getReservationAllSMSSend() {
      if (!this.inputDateRangeValue.from) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["예약일자"])
        );
      }
      if (!this.inputDateRangeValue.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let params = {
        resveDateStart: this.inputDateRangeValue.from,
        resveDateEnd: this.inputDateRangeValue.to,
        hopeTimeZone: this.searchConditions.hopeTimeZone === "ALL" ? null : this.searchConditions.hopeTimeZone,
        smsSendFlag:
          this.searchConditions.smsSendFlag === "%"
            ? null
            : this.searchConditions.smsSendFlag === "1",
        grpKindList:
          this.searchConditions.grpKindList?.length > 0 &&
          this.searchConditions.grpKindList?.length !==
            commonCodesGetCommonCode("GRP_KIND")?.length
            ? this.searchConditions.grpKindList
            : undefined,
        resveName: this.searchConditions.resveName
          ? this.searchConditions.resveName
          : undefined,
        membershipId: this.searchConditions.membershipId
          ? this.searchConditions.membershipId
          : undefined,
        resveCourse:
          this.searchConditions.resveCourseList?.length > 0 &&
          this.searchConditions.resveCourseList?.length !==
            commonCodesGetCommonCode("COURSE_CODE", true)?.length
            ? this.searchConditions.resveCourseList
            : undefined,
        resveBsnCode:
          this.searchConditions.resveBsnCodeList?.length > 0 &&
          this.searchConditions.resveBsnCodeList?.length !==
            commonCodesGetCommonCode("BSN_CODE", true)?.length
            ? this.searchConditions.resveBsnCodeList
            : undefined,
        resvePartDiv:
          this.searchConditions.resvePartDivList?.length > 0 &&
          this.searchConditions.resvePartDivList?.length !==
            commonCodesGetCommonCode("PART_DIV", true)?.length
            ? this.searchConditions.resvePartDivList
            : undefined,
        resveDwcode:
          this.searchConditions.resveDwcodeList?.length > 0 &&
          this.searchConditions.resveDwcodeList?.length !==
            commonCodesGetCommonCode("DW_CODE", true)?.length
            ? this.searchConditions.resveDwcodeList
            : undefined,
        resveMemberDiv:
          this.searchConditions.resveMemberDivList?.length > 0 &&
          this.searchConditions.resveMemberDivList?.length !==
            commonCodesGetCommonCode("MEMBER_DIV", true)?.length
            ? this.searchConditions.resveMemberDivList
            : undefined,
        resveGrpName: this.searchConditions.resveGrpName
          ? this.searchConditions.resveGrpName
          : undefined,
        resveGrpNo: this.searchConditions.resveGrpNo
          ? this.searchConditions.resveGrpNo
          : undefined,
        resvePhoneEndNum: this.searchConditions.resvePhoneEndNum
          ? this.searchConditions.resvePhoneEndNum
          : undefined,
        resveRemarks: this.searchConditions.resveRemarks
          ? this.searchConditions.resveRemarks
          : undefined,
        aditTeamFlag: this.searchConditions.aditTeamFlag,
      };

      const { value } = await getReservationAllSMSSend(params);

      this.toExcludeIndexList = [];
      this.reservationBatchSmsSendList = _orderBy(value.reservationSMSList.map(
        (item) => {
          item.select = false;
          item.smsRecptnDiv =
            item.smsRecptnDiv ||
            commonCodeGetComCodeDefaultValue("SMS_RECPTN_DIV");
          item.cmpnName = item.cmpnInfos?.map(item => item.cmpnName)?.join(', ');
          item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.resveCourse);
          item.grpKindSortNo = item.grpKind === "FIT" ? 1 : 2;
          return item;
        }
      ), ["resveDate", "resveTime", "grpKindSortNo", "courseCodeSortNo"]);
      console.log("order by",this.reservationBatchSmsSendList);
      this.reservationBatchSmsSendList.forEach((d,idx)=> {d._rid = idx+1;});
      console.log("this.reservationBatchSmsSendList",this.reservationBatchSmsSendList);
      this.reservationBatchSmsSendListLength = value.reservationSMSList.length;
    },
    reservationBatchSmsSendListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.reservationBatchSmsSendListGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.reservationBatchSmsSendListGrid.excelExport();
    },
    onResveNameInputChange(args) {
      if (!args.value) {
        this.searchConditions.membershipId = null;
      }
    },
  },
};
</script>
